type RoleUser = 'ADMIN' | 'FUNCIONARIO' | 'GERENTE' | 'ASSISTENTE' | 'AGENTE';
export interface IUserInitialState {
  userEmail: string;
  userID: number | null;
  role: RoleUser | null;
  userName: string;
}

export const INITIAL_STATE: IUserInitialState = {
  userEmail: '',
  userID: Number.NaN,
  role: null,
  userName: '',
};
