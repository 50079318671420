import React from 'react';
import { Text } from '@components/texts';
import { Container } from './styles';

const FakeScreen: React.FC = () => {
  return (
    <Container>
      <Text>Página não encontrada</Text>
    </Container>
  );
};

export default FakeScreen;
