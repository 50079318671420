/* eslint-disable no-unused-expressions */
import React, { useRef } from 'react';
import { FaCaretDown } from 'react-icons/fa';

import { Box, Icon } from '@material-ui/core';
import { Wrapper, AvatarWrapper, WrapperRow, Text } from './styles';

interface IAvatarSectionProps {
  userName: string;
  userRole: string;
  handleFileInput: any;
  avatar: string;
  slotSettings: React.ReactNode;
  onOpenSettingsSection?: (e: React.MouseEvent) => void;
}

type Ref = HTMLDivElement;

const AvatarSection: React.ForwardRefRenderFunction<
  Ref,
  IAvatarSectionProps
> = ({ userName, userRole, onOpenSettingsSection, slotSettings }, ref) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const userNameFormatted = userName.split(' ');

  return (
    <Wrapper>
      <AvatarWrapper
        onClick={() => {
          inputFileRef.current?.click();
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon style={{ fontSize: 60, margin: '20px', color: '#8d97ad' }}>
            account_circle
          </Icon>
        </Box>
      </AvatarWrapper>
      <WrapperRow onClick={onOpenSettingsSection} tabIndex={0} ref={ref}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: '16px' }}>
            {userNameFormatted.length > 1
              ? `${userNameFormatted[0]} ${
                  userNameFormatted[userNameFormatted.length - 1]
                }`
              : `${userNameFormatted[0]}`}
          </Text>
          <Text style={{ fontSize: '11px' }}>{userRole}</Text>
          <FaCaretDown size={18} color="#8d97ad" />
          {slotSettings}
        </Box>
      </WrapperRow>
    </Wrapper>
  );
};

export default React.forwardRef(AvatarSection);
