/* eslint-disable no-param-reassign */
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1200000,
});

api.interceptors.request.use(
  (config) => {
    const userEmail = localStorage.getItem('email');

    if (userEmail) {
      config.headers['User-Email'] = userEmail;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.data?.message === 'Unauthorized') {
      window.localStorage.setItem('persist:cmanager', '');
      window.location.href = '/';

      return Promise.reject(error);
    }

    // window.localStorage.setItem('persist:cmanager', '');
    // window.location.href = '/';

    return Promise.reject(error);
  },
);

export default api;
