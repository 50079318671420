import React from 'react';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { MdAddCircleOutline } from 'react-icons/md';

interface ButtonProps {
  label: string;
  type?: 'button' | 'reset' | 'submit';
  onClick(e: React.MouseEvent): void;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'inherit' | 'secondary';
  className?: string;
  width?: string;
  height?: string;
  icon?: any;
  disabled?: boolean;
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

const ButtonIconMaterial: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = 'button',
  color,
  variant,
  disabled = false,
  icon = <MdAddCircleOutline size={16} />,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        startIcon={icon}
        onClick={onClick}
        type={type}
        color={color}
        variant={variant}
        disabled={disabled}
      >
        {label}
      </Button>
    </ThemeProvider>
  );
};

export default ButtonIconMaterial;
