/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table as MaterialTable,
  TableBody,
  TableFooter,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import WarningModal from '@components/modals/warning-modal';
import { toast } from 'react-toastify';
import { formatDateUsToBr } from '@utils/format-date-us-to-br';
import { Pagination } from '@material-ui/lab';
import api from '../../../services/api';
import { useStyles } from './styles';
import { formatNumberEnToBr } from '../../../utils/format-number-en-to-br';

interface HeaderProps {
  canDelete: boolean;
}

const Header: React.FC<HeaderProps> = ({ canDelete }) => {
  return (
    <>
      <TableCell>Convenio Grupo Nome</TableCell>
      <TableCell>Linhas</TableCell>
      <TableCell>Segmento</TableCell>
      <TableCell>Tiquete</TableCell>
      <TableCell>Nível</TableCell>
      <TableCell>Agente exclusivo</TableCell>
      <TableCell>Faixa de Taxas</TableCell>
      <TableCell>Faixa de Taxas</TableCell>
      <TableCell>Prazo Inicial</TableCell>
      <TableCell>Prazo Final</TableCell>
      <TableCell>Tabela 1</TableCell>
      <TableCell>Tabela 2</TableCell>
      <TableCell>Inicio vigência</TableCell>
      <TableCell>Fim vigência</TableCell>
      {canDelete && <TableCell>Editar</TableCell>}
      {canDelete && <TableCell>Excluir</TableCell>}
    </>
  );
};

const GridAgenteCDC = () => {
  const [page, setPage] = useState(1);
  const [totalCdcTaxasAgente, setTotalCdcTaxasAgente] = useState(0);
  const [renderedCdcTaxasAgente, setRenderedCdcTaxasAgente] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>();
  const [isDeleted, setIsDeleted] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const userRole = useSelector((state: any) => state.user.role ?? 'AGENTE');
  const ROLES_CAN_DELETE = ['ADMIN', 'GERENTE', 'FUNCIONARIO'];
  const canDelete = ROLES_CAN_DELETE.includes(userRole);

  const confirmModalDelete = (id: number) => {
    setIsOpen(true);
    setIdToDelete(id);
  };

  useEffect(() => {
    const getCdcTaxasAgente = async () => {
      setLoading(true);

      await api
        .get(`/cdc-taxas-agente/load?page=${page}`)
        .then((response) => {
          const cdcTaxasAgenteData = response.data.cdc_taxas_agente.map(
            (taxa: any) => {
              const taxaToEdit = taxa.id;

              const linhas = taxa.cdc_taxas_agente_linhas.map(
                (linha) => linha.linhas.linha,
              );

              return (
                <TableRow className={classes.header}>
                  <TableCell>
                    {taxa.convenio_grupo_nome?.nome || 'NÃO SE APLICA'}
                  </TableCell>
                  <TableCell>{linhas.join(', ')}</TableCell>
                  <TableCell>
                    {taxa.segmento === null
                      ? 'NÃO SE APLICA'
                      : taxa.segmento === '1'
                      ? 'PUBLICO'
                      : 'PRIVADO'}
                  </TableCell>
                  <TableCell>{formatNumberEnToBr(taxa.tiquete)}</TableCell>
                  <TableCell>{taxa.nivel_agente.nome_nivel}</TableCell>
                  <TableCell>
                    {taxa.cdc_taxas_agente_gcpfs.length > 0 ? 'SIM' : 'NAO'}
                  </TableCell>
                  <TableCell>{formatNumberEnToBr(taxa.tx_inicial)}</TableCell>
                  <TableCell>{formatNumberEnToBr(taxa.tx_final)}</TableCell>
                  <TableCell>{taxa.prazo_inicio}</TableCell>
                  <TableCell>{taxa.prazo_fim}</TableCell>
                  <TableCell>{formatNumberEnToBr(taxa.rem_taxa_1)}</TableCell>
                  <TableCell>{formatNumberEnToBr(taxa.rem_taxa_2)}</TableCell>
                  <TableCell>
                    {formatDateUsToBr(taxa.inicio_vigencia)}
                  </TableCell>
                  <TableCell>{formatDateUsToBr(taxa.fim_vigencia)}</TableCell>
                  {canDelete && (
                    <TableCell>
                      <MdEdit
                        size={18}
                        onClick={() => handleEditTaxa(taxaToEdit)}
                      />
                    </TableCell>
                  )}
                  {canDelete && (
                    <TableCell>
                      <MdDelete
                        size={18}
                        onClick={() => confirmModalDelete(taxa.id)}
                        color="red"
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            },
          );

          setTotalCdcTaxasAgente(response.data.total);
          setRenderedCdcTaxasAgente(cdcTaxasAgenteData);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getCdcTaxasAgente();
  }, [page, isDeleted]);

  const handleEditTaxa = (id: string) => {
    history.push(`/taxas/cadastro-remuneracao/agente-cdc?id=${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async () => {
    try {
      await api.post('/cdc-taxas-agente/delete', { id: idToDelete });
      toast.success(`Taxa deletada com sucesso`);
      setIsDeleted(isDeleted + 1);
    } catch (e) {
      const error = e as any;
      toast.error(`${error.response.data.message}`);
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <MaterialTable className={classes.root} title="Taxas" color="primary">
          <TableHead>
            <TableRow className={classes.header}>
              <Header canDelete={canDelete} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {renderedCdcTaxasAgente}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <div className={classes.pagination}>
                  <Pagination
                    count={Math.ceil(totalCdcTaxasAgente / 10)}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                    disabled={loading}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </MaterialTable>
      </TableContainer>
      <WarningModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleDelete}
        message="Deseja remover a taxa?"
      />
    </>
  );
};

export default GridAgenteCDC;
