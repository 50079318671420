import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const AvatarWrapper = styled.div`
  width: 60px;
  height: 80px;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  input {
    display: none;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  position: relative;
  outline: 0;
`;

export const Text = styled.span`
  font-size: 15px;
  color: #8d97ad;
`;

interface IAvatar {
  src: any;
}

export const Avatar = styled.div<IAvatar>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
