import { Record } from 'immutable';
import { IBanco, BancoRecord } from '../../models/banco/banco';

export interface IPessoaFisicaConta {
  readonly id: string;
  readonly agencia: string;
  readonly agencia_dv: string;
  readonly conta: string;
  readonly conta_dv: string;
  readonly operacao: string;
  readonly tipo_conta: string;
  readonly banco: IBanco;
}

const defaultValues: IPessoaFisicaConta = {
  id: '',
  agencia: '',
  agencia_dv: '',
  conta: '',
  conta_dv: '',
  operacao: '',
  tipo_conta: '',
  banco: new BancoRecord(),
};

export interface IPessoaFisicaContaRecord
  extends Record<IPessoaFisicaConta>,
    IPessoaFisicaConta {}

export class PessoaFisicaContaRecord extends Record(defaultValues) {}

export const fromJSON = (json) => {
  const id = json.id !== null ? String(json.id) : defaultValues.id;
  const agencia = json.agencia !== null ? json.agencia : defaultValues.agencia;
  const agencia_dv =
    json.agencia_dv !== null ? json.agencia_dv : defaultValues.agencia_dv;
  const conta = json.conta !== null ? json.conta : defaultValues.conta;
  const conta_dv =
    json.conta_dv !== null ? json.conta_dv : defaultValues.conta_dv;
  const operacao =
    json.operacao !== null ? json.operacao : defaultValues.operacao;
  const tipo_conta =
    json.tipo_conta !== null ? json.tipo_conta : defaultValues.tipo_conta;
  const banco = json.banco !== null ? json.banco : defaultValues.banco;

  return new PessoaFisicaContaRecord({
    id,
    agencia,
    agencia_dv,
    conta,
    conta_dv,
    operacao,
    tipo_conta,
    banco,
  });
};
