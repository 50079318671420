import React, { lazy } from 'react';

import { Switch } from 'react-router-dom';

import ClientesPfDetails from 'src/screens/clientes-pf/detalhes';
import EmpresaCDC from 'src/screens/empresa-cdc';
import AgenteCDC from 'src/screens/agente-cdc';
import CadastroEmpresaConsorcio from 'src/screens/empresa-consorcio';
import FakeScreen from 'src/screens/fake-screen';
import CadastroEmpresaCDC from '../screens/empresa-cdc/form';
import CadastroAgenteCDC from '../screens/agente-cdc/form';
import NoLayout from './no-layout';
import PrivateRoutes from './private-routes';

const Home = lazy(() => import('../screens/home'));
const Login = lazy(() => import('../screens/login'));

const CreatePassword = lazy(() => import('../screens/create-password/index'));
const ChangePassword = lazy(() => import('../screens/change-password/index'));
const ForgotPassword = lazy(() => import('../screens/forgot-password/index'));

const Agencia = lazy(() => import('../screens/agencia'));
const AgenciaRegister = lazy(() =>
  import('../screens/agencia/form-agencia/CreateAgenciaForm'),
);
const AgenciaUpdate = lazy(() =>
  import('../screens/agencia/form-agencia/EditFormAgencia'),
);

const ChavesJ = lazy(() => import('../screens/chaves-j'));
const ChavesJRegister = lazy(() => import('../screens/chaves-j/form'));

const Convenios = lazy(() => import('../screens/convenios'));
const ConveniosRegister = lazy(() => import('../screens/convenios/form'));

const ConveniosGrupos = lazy(() => import('../screens/convenios-grupos'));
const ConveniosGruposRegister = lazy(() =>
  import('../screens/convenios-grupos/form'),
);

const Periodos = lazy(() => import('../screens/periodos'));
const PeriodosRegister = lazy(() =>
  import('../screens/periodos/form-periodos/CreatePeriodosForm'),
);
const PeriodosUpdate = lazy(() =>
  import('../screens/periodos/form-periodos/EditFormPeriodos'),
);

const Empresas = lazy(() => import('../screens/empresas'));
const EmpresasRegister = lazy(() =>
  import('../screens/empresas/form-empresas/CreateEmpresasForm'),
);
const EmpresasUpdate = lazy(() =>
  import('../screens/empresas/form-empresas/EditEmpresasForm'),
);

const EmpresaMetasCdc = lazy(() => import('../screens/empresa-metas-cdc'));
const CadastroCdcMetasEmpresa = lazy(() =>
  import('../screens/empresa-metas-cdc/form'),
);

const RegionalMetaCdc = lazy(() => import('../screens/regional-meta-cdc'));
const CadastroRegionalMetaCdc = lazy(() =>
  import('../screens/regional-meta-cdc/form'),
);

const AgenteMetaCdc = lazy(() => import('../screens/agente-meta-cdc'));
const CadastroAgenteMetaCdc = lazy(() =>
  import('../screens/agente-meta-cdc/form'),
);

const Linhas = lazy(() => import('../screens/linhas'));
const LinhasRegister = lazy(() =>
  import('../screens/linhas/form-linhas/CreateLinhasForm'),
);
const LinhasUpdate = lazy(() =>
  import('../screens/linhas/form-linhas/EditLinhasForm'),
);

const Regional = lazy(() => import('../screens/regional'));
const RegionalRegister = lazy(() =>
  import('../screens/regional/form/CreateRegionalForm'),
);
const RegionalUpdate = lazy(() =>
  import('../screens/regional/form/EditFormRegional'),
);

const RecalculaCreditoPF = lazy(() =>
  import('../screens/recalcula-credito-pf'),
);

/* Pessoa Física */
const PessoaFisica = lazy(() => import('../screens/pessoa-fisica'));
const PessoaFisicaRegister = lazy(() =>
  import('../screens/pessoa-fisica/create-pessoa-fisica'),
);
const PessoaFisicaUpdate = lazy(() =>
  import('../screens/pessoa-fisica/update-pessoa-fisica'),
);

/* Pessoa Jurídica */
const PessoaJuridica = lazy(() => import('../screens/pessoa-juridica'));
const PessoaJuridicaRegister = lazy(() =>
  import('../screens/pessoa-juridica/create-pessoa-juridica'),
);
const PessoaJuridicaUpdate = lazy(() =>
  import('../screens/pessoa-juridica/update-pessoa-juridica'),
);

/* Relatórios */
const RelatorioCdc = lazy(() => import('../screens/relatorios/relatorio-cdc'));
const RelatorioConsorcio = lazy(() =>
  import('../screens/relatorios/relatorio-consorcio'),
);
const RelatorioFaturamentoCdc = lazy(() =>
  import('../screens/relatorios/billing-report'),
);
const RelatorioCreditoAgente = lazy(() =>
  import('../screens/relatorios/credito-agente'),
);
const RelatorioCreditoOnlyAgente = lazy(() =>
  import('../screens/relatorios/credito-only-agente'),
);
const RelatorioCreditoPrt = lazy(() =>
  import('../screens/relatorios/cdc-prt-report'),
);

const RelatorioCompliance = lazy(() =>
  import('../screens/relatorios/compliance-report'),
);

const Retorno = lazy(() => import('../screens/retorno'));
const Documentos = lazy(() => import('../screens/documentos'));
const DocumentosForm = lazy(() => import('../screens/documentos/form'));
const MeusDocumentos = lazy(() => import('../screens/meus-documentos'));
const Consorcio = lazy(() => import('../screens/consorcio'));
const FormEditarPerfil = lazy(() => import('../screens/perfil'));
const ConsorcioDetalhes = lazy(() => import('../screens/consorcio/details'));

const CreditoPf = lazy(() => import('../screens/credito-pf'));
const CreditoPfRegister = lazy(() => import('../screens/credito-pf/form'));

const TransferenciaCredito = lazy(() =>
  import('../screens/transferencia-credito'),
);

const ClientesPf = lazy(() => import('../screens/clientes-pf'));

const PrtTaxa = lazy(() => import('../screens/empresa-prt'));
const PrtTaxaRegister = lazy(() =>
  import('../screens/empresa-prt/form-empresa-prt/CreatePrtTaxaForm'),
);
const PrtTaxaUpdate = lazy(() =>
  import('../screens/empresa-prt/form-empresa-prt/EditPrtTaxaForm'),
);

const EmpresaContas = lazy(() => import('../screens/empresa-contas/'));
const EmpresaContasUpdate = lazy(() =>
  import('../screens/empresa-contas/form-empresa-contas'),
);

const AgenteContas = lazy(() => import('../screens/agente-contas/'));
const AgenteContasUpdate = lazy(() =>
  import('../screens/agente-contas/form-agente-contas'),
);

const SeguroAgente = lazy(() => import('../screens/cdc-seguro-agente/'));
const SeguroAgenteUpdate = lazy(() =>
  import('../screens/cdc-seguro-agente/form-cdc-seguro-agente'),
);

const NivelAgente = lazy(() => import('../screens/nivel-agente/'));

const NivelAgenteRegister = lazy(() =>
  import('../screens/nivel-agente/form-nivel-agente/CreateNivelAgenteForm'),
);
const NivelAgenteUpdate = lazy(() =>
  import('../screens/nivel-agente/form-nivel-agente/EditNivelAgenteForm'),
);

const Routes = () => (
  <>
    <Switch>
      <NoLayout exact path="/login" component={Login} />
      <NoLayout exact path="/forgot-password" component={ForgotPassword} />
      <NoLayout
        exact
        path="/create-password/:token"
        component={CreatePassword}
      />

      {/* Dashboard */}
      <PrivateRoutes
        exact
        path="/dashboard"
        component={Home}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/dashboard/cdc-meta-realizado"
        component={Home}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/change-password/:userID"
        component={ChangePassword}
        roles={['ADMIN']}
      />

      {/* Cadastro PF / PJ */}
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-fisica"
        component={PessoaFisica}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-fisica/create"
        component={PessoaFisicaRegister}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-fisica/update/:pessoaFisicaID"
        component={PessoaFisicaUpdate}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-juridica"
        component={PessoaJuridica}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-juridica/create"
        component={PessoaJuridicaRegister}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-juridica/update/:pessoaJuridicaID"
        component={PessoaJuridicaUpdate}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/agente-metas-cdc"
        component={AgenteMetaCdc}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/agente-metas-cdc/create"
        component={CadastroAgenteMetaCdc}
        roles={['ADMIN']}
      />

      {/* Cadastro Operacional */}
      <PrivateRoutes
        exact
        path="/cadastros-operacional/agencia"
        component={Agencia}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/agencia/create"
        component={AgenciaRegister}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/agencia/update/:id"
        component={AgenciaUpdate}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/chaves-j"
        component={ChavesJ}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/chaves-j/form"
        component={ChavesJRegister}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios"
        component={Convenios}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios/form"
        component={ConveniosRegister}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios-grupos"
        component={ConveniosGrupos}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios-grupos/form"
        component={ConveniosGruposRegister}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/periodos"
        component={Periodos}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/periodos/create"
        component={PeriodosRegister}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/periodos/update/:id"
        component={PeriodosUpdate}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/retorno"
        component={Retorno}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/documentos-de-apoio/documentos"
        component={Documentos}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/documentos-de-apoio/documentos/create"
        component={DocumentosForm}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/documentos-de-apoio/relatorio-compliance"
        component={RelatorioCompliance}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/documentos-de-apoio/meus-documentos"
        component={MeusDocumentos}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/linhas"
        component={Linhas}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/linhas/create"
        component={LinhasRegister}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/linhas/update/:id"
        component={LinhasUpdate}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />

      {/* Gestão de empresas */}
      <PrivateRoutes
        exact
        path="/cadastro-empresa/empresas"
        component={Empresas}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-empresa/empresas/create"
        component={EmpresasRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-empresa/empresas/update/:id"
        component={EmpresasUpdate}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-empresa/metas-cdc"
        component={EmpresaMetasCdc}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-empresa/metas-cdc/create"
        component={CadastroCdcMetasEmpresa}
        roles={['ADMIN', 'FUNCIONARIO']}
      />

      {/* Cadastro Regional */}
      <PrivateRoutes
        exact
        path="/cadastro-regional/regional"
        component={Regional}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-regional/regional/create"
        component={RegionalRegister}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-regional/regional/update/:id"
        component={RegionalUpdate}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-regional/metas-cdc"
        component={RegionalMetaCdc}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-regional/metas-cdc/create"
        component={CadastroRegionalMetaCdc}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO']}
      />

      {/* Relatórios */}
      <PrivateRoutes
        exact
        path="/relatorios/relatorio-cdc"
        component={RelatorioCdc}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/relatorios/credito-agente"
        component={RelatorioCreditoAgente}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/relatorios/credito-only-agente"
        component={RelatorioCreditoOnlyAgente}
        roles={['AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/relatorios/billing-report"
        component={RelatorioFaturamentoCdc}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/relatorios/cdc-prt-report"
        component={RelatorioCreditoPrt}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/relatorios/relatorio-consorcio"
        component={RelatorioConsorcio}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/modulos/sub-modulo/sub-sub-modulo/relatorio-consorcio"
        component={RelatorioConsorcio}
        roles={['ADMIN']}
      />

      {/* Perfil */}
      <PrivateRoutes
        exact
        path="/editar-perfil"
        component={FormEditarPerfil}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO', 'ASSISTENTE', 'AGENTE']}
      />

      {/* Produtos */}
      <PrivateRoutes
        exact
        path="/produtos/credito-pf"
        component={CreditoPf}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/credito-pf/form/:id"
        component={CreditoPfRegister}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/produtos/transferencia-credito"
        component={TransferenciaCredito}
        roles={['ADMIN', 'FUNCIONARIO', 'GERENTE']}
      />
      <PrivateRoutes
        exact
        path="/produtos/consorcio"
        component={Consorcio}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/modulos/consorcio/detalhes"
        component={ConsorcioDetalhes}
        roles={['ADMIN']}
      />

      {/* Clientes */}
      <PrivateRoutes
        exact
        path="/clientes/clientes-pf"
        component={ClientesPf}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/modulos/clientes-pf/detalhes/:id"
        component={ClientesPfDetails}
        roles={['ADMIN']}
      />

      {/* Taxas Remuneração */}
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/empresa-cdc"
        component={CadastroEmpresaCDC}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/taxas/empresa-cdc"
        component={EmpresaCDC}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/taxas/agente-cdc"
        component={AgenteCDC}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/agente-cdc"
        component={CadastroAgenteCDC}
        roles={['ADMIN', 'GERENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/prt-taxa"
        component={PrtTaxa}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/taxas/prt-taxa/create"
        component={PrtTaxaRegister}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/taxas/prt-taxa/update/:id"
        component={PrtTaxaUpdate}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/taxas/agente-contas"
        component={AgenteContas}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/agente-contas"
        component={AgenteContasUpdate}
        roles={['ADMIN', 'GERENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/cdc-seguro-agente"
        component={SeguroAgente}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/cdc-seguro-taxa-agente"
        component={SeguroAgenteUpdate}
        roles={['ADMIN', 'GERENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/empresa-contas"
        component={EmpresaContas}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/empresa-contas"
        component={EmpresaContasUpdate}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/empresa-consorcio"
        component={CadastroEmpresaConsorcio}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/taxas/nivel-agente"
        component={NivelAgente}
        roles={['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/nivel-agente/create"
        component={NivelAgenteRegister}
        roles={['ADMIN', 'GERENTE']}
      />
      <PrivateRoutes
        exact
        path="/taxas/nivel-agente/update/:id"
        component={NivelAgenteUpdate}
        roles={['ADMIN', 'GERENTE']}
      />

      {/* Recalcular Comissão */}
      <PrivateRoutes
        exact
        path="/recalcula-comissao/credito-pf"
        component={RecalculaCreditoPF}
        roles={['ADMIN']}
      />

      <PrivateRoutes
        exact
        path="*"
        component={FakeScreen}
        roles={['ADMIN', 'GERENTE', 'FUNCIONARIO', 'ASSISTENTE', 'AGENTE']}
      />
    </Switch>
  </>
);

export default Routes;
