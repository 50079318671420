import React from 'react';

import { Button, CircularProgress } from '@material-ui/core';

import { Container, UseStyles } from './styles';

type ButtonLoaderMaterialProps = {
  loading: boolean;
  label: string;
  onClick?: any;
  type?: 'submit' | 'reset' | 'button';
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary';
  width?: string;
  style?: any;
  disabled?: boolean;
};

const ButtonLoaderMaterial: React.FC<ButtonLoaderMaterialProps> = ({
  type,
  onClick,
  loading,
  label,
  variant,
  color,
  width,
  style,
  disabled = false,
}) => {
  const classes = UseStyles();

  return (
    <Container width={width}>
      <Button
        variant={variant}
        color={color}
        type={type}
        disabled={loading || disabled}
        onClick={onClick}
        style={style}
      >
        {label}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Container>
  );
};

export default ButtonLoaderMaterial;
