import { ApplicationState } from '../..';
import { createSelector } from '../../../class/memoize/createSelector';
import { getPessoaFisicaByPessoaFisicaIDs } from '../../core/pessoa-fisica/selectors';
import { TipoCadastroType } from './types';

const getState = (state: ApplicationState) => state.gcpf.form;

const getIsLoading = createSelector([getState], (state) => state.isLoading);

const getFinalizationStatus = createSelector(
  [getState],
  (state) => state.createFinalizationStatus,
);

const getSelectedPessoaFisicaID = createSelector(
  [getState],
  (state) => state.selectedPessoaFisicaID,
);

const getPessoaFisicaFormDTOByPessoaFisicaID = createSelector(
  [getPessoaFisicaByPessoaFisicaIDs],
  (pessoaFisicaByPessoaFisicaIDs) =>
    pessoaFisicaByPessoaFisicaIDs.map((pessoaFisica) => {
      const tipo_cadastro: TipoCadastroType[] = [];
      if (pessoaFisica.fornecedor) {
        tipo_cadastro.push({
          value: 'tipo_fornecedor',
          label: 'Tipo Fornecedor',
        });
      }
      if (pessoaFisica.cliente) {
        tipo_cadastro.push({
          value: 'tipo_cliente',
          label: 'Tipo cliente',
        });
      }
      if (pessoaFisica.funcionario) {
        tipo_cadastro.push({
          value: 'tipo_funcionario',
          label: 'Tipo Colaborador',
        });
      }

      return {
        nome: pessoaFisica.nome,
        data_nasc: pessoaFisica.data_nasc,
        cpf: pessoaFisica.cpf,
        rg: pessoaFisica.rg,
        orgao_emissor: pessoaFisica.orgao_emissor,
        sexo: pessoaFisica.sexo,
        tipo_cadastro,
        id_banco: {
          value: pessoaFisica.conta.banco.id,
          label: pessoaFisica.conta.banco.nome_banco,
        },
        agencia: pessoaFisica.conta.agencia,
        agencia_dv: pessoaFisica.conta.agencia_dv,
        conta: pessoaFisica.conta.conta,
        conta_dv: pessoaFisica.conta.conta_dv,
        operacao: pessoaFisica.conta.operacao || '',
        tipo_conta: pessoaFisica.conta.tipo_conta,
        cep: pessoaFisica.endereco.logradouro.cep,
        logradouro: pessoaFisica.endereco.logradouro.logradouro,
        numero: pessoaFisica.endereco.numero,
        complemento: pessoaFisica.endereco.complemento,
        cidade: pessoaFisica.endereco.cidade.nome,
        uf: pessoaFisica.endereco.uf.nome,
        email: pessoaFisica.email.email,
        ddd: pessoaFisica.telefone.ddd,
        telefone: pessoaFisica.telefone.telefone,
        ddd_2: pessoaFisica.telefone.ddd_2,
        telefone_2: pessoaFisica.telefone.telefone_2,
        id_logradouro: Number(pessoaFisica.endereco.logradouro.id),
        id_uf: Number(pessoaFisica.endereco.uf.id),
        id_cidade: Number(pessoaFisica.endereco.cidade.id),
        role: pessoaFisica.role,
      };
    }),
);

export default Object.freeze({
  getIsLoading,
  getFinalizationStatus,
  getSelectedPessoaFisicaID,
  getPessoaFisicaFormDTOByPessoaFisicaID,
});
