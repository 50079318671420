export type RolesTypes =
  | 'ADMIN'
  | 'FUNCIONARIO'
  | 'GERENTE'
  | 'ASSISTENTE'
  | 'AGENTE';
export interface Item {
  label: string;
  icon: string;
  route: string;
  roles: RolesTypes[];
  subMenu?: Item[];
  checkRoute?: string;
}

export interface Menu {
  title: string;
  icon: string;
  roles: RolesTypes[];
  children: Item[];
  checkRoute: string;
}

export interface Documentos {
  title: string;
  icon: string;
  roles: RolesTypes[];
  children: Item[];
  checkRoute: string;
}

export interface Nav {
  items: (Item | Menu)[];
}

export const dashboard: Item = {
  label: 'Dashboard',
  icon: 'GrLineChart',
  route: '/dashboard',
  roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
};

const cadastros: Menu = {
  title: 'Cadastro PF/PJ',
  icon: 'MdPersonAdd',
  roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
  checkRoute: 'cadastros',
  children: [
    {
      icon: 'MdPersonAdd',
      route: '/cadastros/pessoa-fisica',
      label: 'Pessoa Física',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
    },
    {
      icon: 'MdPersonAdd',
      route: '/cadastros/pessoa-juridica',
      label: 'Pessoa Jurídica',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
    },
    {
      icon: 'FaUserTie',
      route: '/agente-metas-cdc',
      label: 'Agente Metas CDC',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
    },
  ],
};

const cadastrosoperacional: Menu = {
  title: 'Cadastro Operacional',
  icon: 'MdAddBox',
  roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
  checkRoute: 'cadastros-operacional',
  children: [
    {
      icon: '',
      route: '/cadastros-operacional/agencia',
      label: 'Agências',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros/chaves-j',
      label: 'Chaves J',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros/convenios',
      label: 'Convênios',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros/convenios-grupos',
      label: 'Convênios grupos',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros-operacional/linhas',
      label: 'Linha de Crédito',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros-operacional/periodos',
      label: 'Períodos',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros-operacional/retorno',
      label: 'Retorno',
      roles: ['ADMIN', 'FUNCIONARIO'],
    },
  ],
};
const cadastroregional: Menu = {
  title: 'Cadastro Regional',
  icon: 'MdBusiness',
  roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO'],
  checkRoute: 'cadastro-regional',
  children: [
    {
      icon: 'FaUserTie',
      route: '/cadastro-regional/regional',
      label: 'Regional',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastro-regional/metas-cdc',
      label: 'Regional Meta CDC',
      roles: ['ADMIN', 'GERENTE'],
    },
  ],
};
const clientes: Menu = {
  title: 'Clientes',
  icon: 'MdPeople',
  roles: ['ADMIN'],
  checkRoute: 'clientes',
  children: [
    {
      icon: 'FaUserTie',
      route: '/clientes/clientes-pf',
      label: 'Clientes PF',
      roles: ['ADMIN'],
    },
  ],
};

const produtos: Menu = {
  title: 'Produtos',
  icon: 'FaProductHunt',
  roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO', 'AGENTE'],
  checkRoute: 'produtos',
  children: [
    {
      icon: 'FaUserTie',
      route: '/produtos/credito-pf',
      label: 'Crédito PF',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO', 'AGENTE'],
    },
    {
      icon: 'FaUserTie',
      route: '/produtos/consorcio',
      label: 'Consórcio',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/produtos/transferencia-credito',
      label: 'Transferencia de Crédito',
      roles: ['ADMIN', 'FUNCIONARIO', 'GERENTE'],
    },
  ],
};
const taxas: Menu = {
  title: 'Taxas Remuneração',
  icon: 'FaPercent',
  roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'FUNCIONARIO', 'AGENTE'],
  checkRoute: 'taxas',
  children: [
    {
      icon: '',
      route: '/taxas/agente-cdc',
      label: 'Agente CDC',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
    },
    {
      icon: '',
      route: '/taxas/agente-contas',
      label: 'Agente Contas',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
    },
    {
      icon: '',
      route: '/taxas/cdc-seguro-agente',
      label: 'Agente Seguro',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
    },
    {
      icon: '',
      route: '/taxas/empresa-cdc',
      label: 'Empresa CDC',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/empresa-contas',
      label: 'Empresa Contas',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/cadastro-remuneracao/empresa-consorcio',
      label: 'Empresa Consórcio',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/prt-taxa',
      label: '% PRT Empresa',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/nivel-agente',
      label: 'Nível Agente',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
    },
  ],
};

const relatorios: Menu = {
  title: 'Relatórios',
  icon: 'FaFile',
  roles: ['ADMIN', 'GERENTE', 'ASSISTENTE', 'AGENTE'],
  checkRoute: 'relatorios',
  children: [
    {
      icon: 'FaUserTie',
      route: '/relatorios/relatorio-cdc',
      label: 'Crédito',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE'],
    },
    {
      icon: 'FaUserTie',
      route: '/relatorios/credito-agente',
      label: 'Crédito Agente',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE'],
    },
    {
      icon: 'FaUserTie',
      route: '/relatorios/credito-only-agente',
      label: 'Crédito Agente',
      roles: ['AGENTE'],
    },
    {
      icon: 'FaUserTie',
      route: '/relatorios/billing-report',
      label: 'Faturamento Crédito',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/relatorios/cdc-prt-report',
      label: 'Crédito PRT',
      roles: ['ADMIN'],
    },
  ],
};
const empresas: Menu = {
  title: 'Gestão de Empresas',
  icon: 'MdBusiness',
  roles: ['ADMIN'],
  checkRoute: 'cadastro-empresa',
  children: [
    {
      icon: 'FaUserTie',
      route: '/cadastro-empresa/empresas',
      label: 'Cadastro Empresa',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastro-empresa/metas-cdc',
      label: 'Metas CDC Empresa',
      roles: ['ADMIN'],
    },
  ],
};
const recalcularComissao: Menu = {
  title: 'Recalcular Comissão',
  icon: 'FaProductHunt',
  roles: ['ADMIN'],
  checkRoute: 'recalcular-comissao',
  children: [
    {
      icon: 'FaUserTie',
      route: '/recalcula-comissao/credito-pf',
      label: 'Crédito PF',
      roles: ['ADMIN'],
    },
  ],
};
const documentos: Documentos = {
  title: 'Documentos',
  icon: 'MdBusiness',
  roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO', 'ASSISTENTE', 'AGENTE'],
  checkRoute: 'documentos-de-apoio',
  children: [
    {
      icon: 'FaUserTie',
      route: '/documentos-de-apoio/documentos',
      label: 'Documentos de apoio',
      roles: ['ADMIN', 'GERENTE', 'ASSISTENTE'],
    },
    {
      icon: 'FaUserTie',
      route: '/documentos-de-apoio/relatorio-compliance',
      label: 'Relatório Compliance',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/documentos-de-apoio/meus-documentos',
      label: 'Meus documentos',
      roles: ['ADMIN', 'GERENTE', 'FUNCIONARIO', 'ASSISTENTE', 'AGENTE'],
    },
  ],
};

const nav: Nav = {
  items: [
    dashboard,
    cadastros,
    cadastrosoperacional,
    cadastroregional,
    clientes,
    produtos,
    relatorios,
    taxas,
    empresas,
    recalcularComissao,
    documentos,
  ],
};

export default nav;
