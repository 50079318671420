import React, { useCallback, useState, useRef } from 'react';

import AvatarSection from '@components/dashboard/menu/avatar-section';
import { toBase64 } from '@utils/base64';
import { useOutsideClick } from '@hooks';

import perfil from '../../../../../assets/logos/foto-perfil.jpg';
import SettingsWired from './settings';

const AvatarSectionWired: React.FC = () => {
  const [avatar, setAvatar] = useState<string | null>(
    window.localStorage.getItem('@gc:avatar'),
  );
  const [isOpen, setIsOpen] = useState(false);
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  const handleOpenSettingsSection = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const handleOutsideClick = useCallback(() => {
    if (!isOpen) return;
    setIsOpen(false);
  }, [isOpen]);

  useOutsideClick(wrapperDivRef, handleOutsideClick);

  const handleInputFileChange = useCallback(async (files) => {
    const fileToBase64 = await toBase64(files[0]);
    window.localStorage.setItem('@gc:avatar', fileToBase64);
    setAvatar(fileToBase64);
  }, []);

  const slotSettings = <SettingsWired isOpen={isOpen} />;
  const localStorage = window.localStorage.getItem('persist:cmanager') as any;

  const localStorageParse = JSON.parse(localStorage);
  const user = JSON.parse(localStorageParse.user);

  return (
    <AvatarSection
      handleFileInput={handleInputFileChange}
      userName={user.userName}
      userRole={user.role}
      avatar={avatar || perfil}
      slotSettings={slotSettings}
      onOpenSettingsSection={handleOpenSettingsSection}
      ref={wrapperDivRef}
    />
  );
};

export default AvatarSectionWired;
