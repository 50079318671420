/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authSelectors, userSelectors } from '@store';
import { toast } from 'react-toastify';
import { Dashboard } from '../screens/template';
import { RolesTypes } from '../nav';

interface PrivateRoutesProps {
  component: React.ElementType;
  exact?: boolean;
  path: string;
  roles: RolesTypes[];
}

const { getIsSigned } = authSelectors;
const { getLoggedUserRole } = userSelectors;

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({
  component: Component,
  exact,
  path,
  roles,
}) => {
  const location = useLocation();
  const isSigned = useSelector(getIsSigned);
  const userRole = useSelector(getLoggedUserRole);

  if (location.pathname === '/') {
    return <Redirect to="/dashboard" />;
  }

  if (!isSigned) {
    if (localStorage.getItem('showMessageLogin') === '1') {
      toast.error('Usuário ou senha inválidos.');
      localStorage.setItem('showMessageLogin', '0');
    }
    return <Redirect to="/login" />;
  }

  if (userRole !== null && !roles.includes(userRole)) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route exact={exact} path={path} roles={roles}>
      <Dashboard>
        <Suspense fallback={<div>carregando...</div>}>
          <Component />
        </Suspense>
      </Dashboard>
    </Route>
  );
};

export default PrivateRoutes;
